<template>
    <div class="web-view-container">
        <iframe ref="webView" class="iframe-container" :src="$route.params.url" frameborder="0" ></iframe>
    </div>
</template>

<script>
    export default {
        name: "WebView",
        data() {
            return {}
        },
        mounted() {
            this.showLoading("Loading")
            if (this.$refs.webView.attachEvent) {
                this.$refs.webView.attachEvent("onload",()=> {
                    this.hideLoading()
                })
            }else {
                this.$refs.webView.onload = ()=> {
                    this.hideLoading()
                }
            }
        },
        methods: {
            backClick() {
                this.$router.back()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .web-view-container {

        .iframe-container {
            width: 100%;
            height: 100%;
            overflow:auto;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
</style>